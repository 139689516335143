import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, toRaw, computed, toRefs, onMounted, watch, nextTick } from 'vue';
import useTablePagination from '@/hooks/pagination';
import applyModal from './components/applyModal.vue';
import applyDetailModal from './components/warehousingApplyDetailModal.vue';
import { selectWarntime, updateWarntime } from '@/api/material';
import Cookies from "js-cookie";
import { entrySelectApplyByPagination, entrySelectApplyById } from '@/api/material';
import moment from 'moment'; //表头

const columns = [{
  title: '主题',
  dataIndex: 'theme'
}, {
  title: '供应商',
  dataIndex: 'supplier'
}, {
  title: '申请时间',
  dataIndex: 'time'
}, {
  title: '入库时间',
  dataIndex: 'warehouseTimes',
  slots: {
    customRender: 'warehouseTimes'
  }
}, {
  title: '申请人',
  dataIndex: 'applicant'
}, {
  title: '入库状态',
  dataIndex: 'approvalStatus',
  slots: {
    customRender: 'approvalStatus'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
export default defineComponent({
  components: {
    applyModal,
    applyDetailModal
  },

  setup() {
    const formRef = ref();
    const formState = reactive({
      supplierName: undefined,
      approvalStatus: null,
      process: null,
      professional: null
    }); //点击查询

    const onSubmit = () => {
      current.value = 1;
      getTableData();
    }; //查询条件重置


    const resetForm = () => {
      formRef.value.resetFields();
      current.value = 1;
      getTableData();
    }; //表格


    const tableData = ref([]);
    const loading = ref(false); //分页信息

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;
      getTableData();
    };

    const getTableData = async () => {
      const param = {
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10,
        supplierName: formState.supplierName ? formState.supplierName : null,
        state: formState.approvalStatus ? formState.approvalStatus : null
      };
      loading.value = true;
      let res = await entrySelectApplyByPagination(param);

      if (res.code === 200) {
        const arr = [];
        tableData.value = [];
        if (res.data.list) res.data.list.forEach(item => {
          arr.push({
            key: item.id,
            theme: item.theme,
            supplier: item.supplierName,
            time: item.approachTime.split(' ')[0],
            warehouseTimes: item.warehouseTimes,
            applicant: item.userName,
            approvalStatus: item.warehouseState,
            isOperate: item.isOperate
          });
        });
        Object.assign(tableData.value, arr);
        total.value = res.data.countSize;
        current.value = res.data.nowPage;
        pageSize.value = res.data.pageSize;
        loading.value = false;
      } else {
        loading.value = false;

        _message.error(res.message);
      }
    }; //详情


    const applyDetailVisible = ref(false);
    const applyDetailData = reactive({
      state: 1,
      data: {}
    }); //查看详情

    const toView = async (item, num) => {
      // console.log(item,num) //num 1详情 7未入库 10部分入库
      const res = await entrySelectApplyById(item.key);

      try {
        applyDetailData.state = num;
        applyDetailData.data = res.data; // applyDetailData.data.approachTime = moment(applyDetailData.data.approachTime, 'YYYY-MM-DD')

        applyDetailVisible.value = true;
      } catch (error) {
        console.log('入库详情:', error);
      }
    }; //关闭详情页


    const closeApplyDetailVisible = val => {
      applyDetailVisible.value = val.isClose;
      applyDetailData.state = 1;
      applyDetailData.data = {};

      if (val.state == 2) {
        getTableData();
      }
    }; //审批时间设置


    const timeVisible = ref(false);
    const timeFormRef = ref();
    const timeFormState = reactive({
      intervalTime: null,
      circulateTime: null,
      id: null,
      type: null,
      belongProject: null
    });

    const handleTimeConf = async () => {
      const param = {
        type: 1,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectWarntime(param);

      if (res.code === 200) {
        Object.assign(timeFormState, res.data);
        timeVisible.value = true;
      } else {
        _message.error(res.message);
      }
    };

    const handleSetTime = () => {
      timeFormRef.value.validate().then(async () => {
        console.log(timeFormState);
        let res = await updateWarntime(timeFormState);

        if (res.code === 200) {
          _message.success(res.message);

          timeVisible.value = false;
        } else {
          _message.error(res.message);
        }
      }).catch(error => {
        console.log('error', error);
      });
    };

    const handleCancel = () => {
      timeVisible.value = false;
      timeFormRef.value.resetFields();
    }; //入库时间


    const setTime = val => {
      if (val.warehouseTimes && val.warehouseTimes.length == 1 && val.approvalStatus == 8) {
        return `<p><b>入库时间：</b>${val.warehouseTimes[0]}</p>`;
      } else if (val.warehouseTimes && val.warehouseTimes.length) {
        let str = "";
        val.warehouseTimes.forEach((ixt, index) => {
          str += `<p><b>第${index + 1}次入库时间：</b>${ixt}</p>`;
        });
        return str;
      }
    };

    onMounted(() => {
      getTableData();
    });
    return {
      formRef,
      formState,
      onSubmit,
      resetForm,
      paginationConfig,
      columns,
      tableData,
      loading,
      onPageChange,
      toView,
      applyDetailVisible,
      closeApplyDetailVisible,
      handleTimeConf,
      timeVisible,
      handleSetTime,
      timeFormState,
      timeFormRef,
      handleCancel,
      applyDetailData,
      setTime
    };
  }

});