import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { PlusCircleOutlined, MinusCircleOutlined, PaperClipOutlined, DeleteOutlined, ProfileOutlined, PartitionOutlined, ShopOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, computed, watch, onMounted } from 'vue';
import getFieldRules from '@/utils/fieldRules';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import { updateApply, updateWarehouse, selectLocationByParam, selectApprovalItem, selectProfessionalByItem } from '@/api/material';
import moment from 'moment';
import printJS from 'print-js';
import Cookies from "js-cookie";
export default defineComponent({
  components: {
    PlusCircleOutlined,
    MinusCircleOutlined,
    PaperClipOutlined,
    uploadImgs,
    DeleteOutlined,
    ProfileOutlined,
    PartitionOutlined,
    ShopOutlined
  },
  props: {
    applyVisible: {
      type: Boolean,
      default: false
    },
    applyDetailData: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['closeApplyVisible'],

  setup(props, context) {
    const formRef = ref();

    const closeApplyVisible = () => {
      formRef.value.resetFields();
      context.emit('closeApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const objForm = reactive({});
    let formState = reactive({
      content: "",
      theme: '',
      materials: [],
      // approvalProcedureDtos:[],
      approvalDtos: [],
      hyApproachApprovals: [],
      supplierName: '',
      supplierContact: '',
      supplierMobile: '',
      packingWay: '',
      transporteWay: '',
      unloadingRequire: '',
      approachTime: null,
      fileUrl: [],
      contractName: "",
      professional: "",
      hyWarehouseDtos: [],
      itemId: null
    });
    const formStateForRuk = reactive({
      content: "",
      warehouseUrl: [],
      warehouseMaterials: []
    }); // console.log('11111',formState)

    watch(() => props.applyDetailData.data, val => {
      if (JSON.stringify(val) !== "{}") {
        formState.theme = props.applyDetailData.data.approachRootBean.theme;
        formState.materials = props.applyDetailData.data.approachRootBean.materials; // formState.approvalProcedureDtos = props.applyDetailData.data.approachRootBean.approvalProcedureDtos

        formState.approvalDtos = props.applyDetailData.data.approachRootBean.approvalDtos;
        formState.itemId = props.applyDetailData.data.approachRootBean.itemId;
        formState.hyApproachApprovals = props.applyDetailData.data.approachRootBean.hyApproachApprovals;
        formState.supplierName = props.applyDetailData.data.approachRootBean.supplierName;
        formState.supplierContact = props.applyDetailData.data.approachRootBean.supplierContact;
        formState.supplierMobile = props.applyDetailData.data.approachRootBean.supplierMobile;
        formState.packingWay = props.applyDetailData.data.approachRootBean.packingWay;
        formState.transporteWay = props.applyDetailData.data.approachRootBean.transporteWay;
        formState.unloadingRequire = props.applyDetailData.data.approachRootBean.unloadingRequire;
        formState.approachTime = moment(props.applyDetailData.data.approachRootBean.approachTime, 'YYYY-MM-DD');
        formState.fileUrl = props.applyDetailData.data.approachRootBean.fileUrl ? JSON.parse(props.applyDetailData.data.approachRootBean.fileUrl) : [];
        formState.contractName = props.applyDetailData.data.approachRootBean.contractName;
        formState.professional = props.applyDetailData.data.approachRootBean.professional;
        formState.hyWarehouseDtos = props.applyDetailData.data.hyWarehouseDtos;
        console.log(formState.hyWarehouseDtos);

        if (props.applyDetailData.state == 7) {
          formStateForRuk.warehouseMaterials = [];
          formState.materials.forEach(ixt => {
            let obj = {
              materialsName: ixt.materialsName,
              materialsSpecs: ixt.materialsSpecs,
              warehouseNum: null,
              lackNum: null,
              applyMaterialsId: ixt.id,
              materialsNum: ixt.materialsNum,
              materialsLocationList: [{
                materialsId: ixt.id,
                methodType: 1,
                materialsNum: null,
                physicsLocation: null
              }]
            };
            formStateForRuk.warehouseMaterials.push(obj);
          });
        }

        if (props.applyDetailData.state == 10) {
          formStateForRuk.warehouseMaterials = [];
          let arr = JSON.parse(JSON.stringify(formState.materials));
          arr.forEach(itemL => {
            itemL.lackNum = null;
            itemL.warehouseNum = itemL.materialsNum;
            itemL.applyMaterialsId = itemL.id;
            formState.hyWarehouseDtos.forEach(item => {
              item.warehouseMaterials.forEach(ixt => {
                if (itemL.id == ixt.applyMaterialsId) {
                  itemL.warehouseNum = itemL.warehouseNum - ixt.warehouseNum;
                  itemL.materialsNum = itemL.materialsNum - ixt.warehouseNum;
                }
              });
            });
            itemL.materialsLocationList = [{
              materialsId: itemL.id,
              methodType: 1,
              materialsNum: null,
              physicsLocation: null
            }];
          });
          formStateForRuk.warehouseMaterials = arr.filter(item => item.warehouseNum !== 0);
        }
      }
    });
    const isDisabledForCl = computed(() => {
      if (props.applyDetailData.state == 1) {
        return true;
      } else if (props.applyDetailData.state == 10) {
        return true;
      } else if (props.applyDetailData.state == 7) {
        return true;
      }
    });
    const isDisabledForSp = computed(() => {
      if (props.applyDetailData.state == 1) {
        return true;
      } else if (props.applyDetailData.state == 10) {
        return true;
      } else if (props.applyDetailData.state == 7) {
        return true;
      }
    });
    const isDisabledForRuk = computed(() => {
      if (props.applyDetailData.state == 1) {
        return true;
      } else if (props.applyDetailData.state == 10) {
        return false;
      } else if (props.applyDetailData.state == 7) {
        return false;
      }
    });
    const {
      checkName,
      checkPhone
    } = getFieldRules();
    const rules = {
      theme: [{
        required: true,
        message: '申请主题不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      packingWay: [{
        required: true,
        message: '包装方式不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      transporteWay: [{
        required: true,
        message: '进场运输方式不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      unloadingRequire: [{
        required: true,
        message: '卸货需求不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      contractName: [{
        required: true,
        message: '归属合同不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      approachTime: [{
        required: true,
        message: '进场时间不能为空',
        trigger: 'change',
        type: 'object'
      }],
      professional: [{
        required: true,
        message: '所属专业不能为空',
        trigger: 'change'
      }],
      supplierName: [{
        required: true,
        message: '供应商名称不能为空',
        trigger: 'blur'
      }],
      supplierContact: [{
        required: true,
        validator: checkName,
        // message: '供应商联系人不能为空',
        trigger: 'blur'
      }],
      supplierMobile: [{
        required: true,
        validator: checkPhone,
        // message: '联系方式不能为空',
        trigger: 'blur'
      }],
      content: [{
        required: true,
        message: '不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      itemId: [{
        required: true,
        message: '审批流程线不能为空',
        type: 'number',
        trigger: 'change'
      }]
    }; //添加材料

    const addMaterialInformation = () => {
      formState.materials.push({
        materialsName: '',
        materialsSpecs: '',
        materialsNum: ''
      });
    }; //删除材料


    const deleteMaterialInformation = index => {
      formState.materials.splice(index, 1);
    }; //获取附件的url


    const getAdjuncts = val => {
      console.log('getAdjuncts', val);
      formState.fileUrl = val;
    };

    const getAdjunctsForRuk = val => {
      console.log('getAdjunctsForRuk', val);
      formStateForRuk.warehouseUrl = val;
    };

    const loading = ref(false); //提交

    const handleOk = () => {
      formRef.value.validate().then(async () => {
        const param = {
          applyId: props.applyDetailData.data.applyId,
          supplierName: formState.supplierName,
          supplierContact: formState.supplierContact,
          supplierMobile: formState.supplierMobile,
          packingWay: formState.packingWay,
          transporteWay: formState.transporteWay,
          unloadingRequire: formState.unloadingRequire,
          contractName: formState.contractName,
          professional: formState.professional,
          approachTime: formState.approachTime.startOf('day').format('YYYY-MM-DD'),
          fileUrl: JSON.stringify(formState.fileUrl),
          materials: [],
          itemId: formState.itemId
        };
        formState.materials.forEach(ixt => {
          param.materials.push({
            id: ixt.id ? ixt.id : null,
            materialsName: ixt.materialsName,
            materialsNum: ixt.materialsNum,
            materialsSpecs: ixt.materialsSpecs
          });
        });
        const res = await updateApply(param);

        if (res.code == 200) {
          _message.success('修改成功！');

          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error('修改失败！');
        }
      }).catch(error => {
        console.log('error', error);
      });
    };

    const setRoleType = num => {
      switch (num) {
        case 0:
          return "申请人";

        case 1:
          return "专业负责人";

        case 2:
          return "库管员";

        case 3:
          return "其他审批人";
      }
    };

    const getCurrent = arr => {
      const newArr = arr.filter(item => item.status == 1);
      return newArr.length - 1;
    };

    const disabledDate = current => {
      return current && current <= moment().subtract(1, 'days');
    };

    const formRefForRuk = ref();
    const isInspect = ref(true);

    const numValidator = async (rules, value) => {
      // console.log('wwwwwwwwww',value)
      if (isInspect.value) {
        if (value === null) {
          return Promise.reject('数量不能为空！');
        } else {
          return Promise.resolve();
        }
      } else {
        return Promise.resolve();
      }
    };

    const weiValidator = async (rules, value) => {
      console.log('wwwwwwwwww', value);
      console.log(isInspect.value);

      if (isInspect.value) {
        if (value === null) {
          return Promise.reject('存放位置不能为空！');
        } else {
          return Promise.resolve();
        }
      } else {
        return Promise.resolve();
      }
    };

    const handlePassOrReject = num => {
      if (num == 0) {
        isInspect.value = false;
      } else {
        isInspect.value = true;
      }

      formRefForRuk.value.validate().then(async () => {
        const param = {
          id: props.applyDetailData.data.id,
          approachApplyId: props.applyDetailData.data.approachApplyId,
          hyWarehouseExplain: {
            content: formStateForRuk.content,
            warehouseUrl: JSON.stringify(formStateForRuk.warehouseUrl),
            state: num
          },
          warehouseMaterials: []
        };
        let isOk = true;

        if (num == 1) {
          formStateForRuk.warehouseMaterials.forEach((item, index) => {
            let sumc = 0;
            item.materialsLocationList.forEach(ixt => {
              sumc += ixt.materialsNum;
            });

            if (item.warehouseNum + item.lackNum !== item.materialsNum) {
              _message.error(`材料名:${item.materialsName}-规格:${item.materialsSpecs},提交入库数量和缺补数量与申请入库数量不一样，请重新填写！`);

              isOk = false;
            } else if (sumc !== item.warehouseNum) {
              _message.error(`材料名:${item.materialsName}-规格:${item.materialsSpecs},提交入库数量和存放位置数量不一样，请重新填写！`);

              isOk = false;
            } else {
              let obj = {
                materialsName: item.materialsName,
                materialsSpecs: item.materialsSpecs,
                warehouseNum: item.warehouseNum,
                lackNum: item.lackNum,
                applyMaterialsId: item.applyMaterialsId,
                materialsLocationList: item.materialsLocationList
              };
              param.warehouseMaterials.push(obj);
            }
          });
        }

        if (!isOk) {
          return;
        }

        const res = await updateWarehouse(param);

        if (res.code == 200) {
          _message.success('操作成功！');

          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error('操作失败！');
        }
      }).catch(error => {
        console.log('error', error);
      });
    };

    const detUrl = () => {
      formState.fileUrl.splice(index, 1);
    }; //打印


    const handlePrint = () => {
      let div = document.createElement('div'); // div.setAttribute('id','printCons');

      div.appendChild(setTable(`材料清单--${formState.theme}`, setTableData(formState.materials)));

      if (formState.hyWarehouseDtos.length) {
        formState.hyWarehouseDtos.forEach((item, index) => {
          let str = formState.hyWarehouseDtos.length == 1 ? `入库清单--${formState.theme}` : `第${index + 1}次入库清单--${formState.theme}`;
          div.appendChild(setTable(str, setTableData(item.warehouseMaterials)));
        });
      }

      printJS({
        printable: div,
        type: 'html',
        style: `
          table {
            border:2px solid #ccc;
            border-bottom: none;
            border-left: none;
            border-collapse: separate;
            border-spacing: 0;
            width: 100%;
          }
          th { 
            border-bottom: 2px solid #ccc;
            width: 25%;
            text-align: left;
            border-left: 2px solid #ccc;
          }
          td { 
            border-bottom: 2px solid #ccc;
            border-left: 2px solid #ccc;
          }
          `,
        // gridHeaderStyle:'font-size:12px; padding:3px; border:1px solid; font-weight: 100; text-align:left;',
        // gridStyle:'font-size:12px; padding:3px; border:1px solid; text-align:left;',
        // repeatTableHeader: true,
        // scanStyles:true,
        targetStyles: ['*'],
        ignoreElements: ['no-print', 'bc', 'gb']
      });
    }; //下载


    const handleDownExcel = () => {
      require.ensure([], () => {
        const {
          export_json_to_excel1
        } = require("@/assets/excel/Export2Excel");

        const tHeader = ["材料名称", "规格", "供应商", "数量"]; // 导出的excel表头名信息

        const titleNameArr = [`入库材料清单--${formState.theme}`]; // 导出的excel表头名信息

        const filterVal = ["materialsName", "materialsSpecs", "supplierName", "materialsNum"]; // 导出的excel表头字段名，需要导出表格字段名

        const list = setTableData(formState.materials);
        const arr = [];
        const data = formatJson(filterVal, list);

        if (formState.hyWarehouseDtos.length) {
          formState.hyWarehouseDtos.forEach((item, index) => {
            let str = formState.hyWarehouseDtos.length == 1 ? `入库清单--${formState.theme}` : `第${index + 1}次入库清单--${formState.theme}`;
            titleNameArr.push(str);
            let data1 = formatJson(filterVal, setTableData(item.warehouseMaterials));
            arr.push(data1);
          });
        }

        const allData = [data, ...arr]; // console.log('allData',allData)

        export_json_to_excel1(tHeader, titleNameArr, allData, `材料清单--${formState.theme}`); // 导出的表格名称，根据需要自己命名
      });
    }; // 数据生成表格


    const setTable = (val, data) => {
      let div = document.createElement('div');
      let table = document.createElement('table');
      let h2 = document.createElement('h2');
      h2.innerHTML = val;
      let tr1 = document.createElement('tr');
      table.appendChild(tr1);
      tr1.innerHTML = '<th>材料名称</th><th>规格</th><th>供应商</th><th>数量</th>';

      for (let i = 0; i < data.length; i++) {
        let tr = document.createElement('tr');
        table.appendChild(tr);

        for (let key in data[i]) {
          let td = document.createElement('td');
          td.textContent = data[i][key];
          tr.appendChild(td);
        }

        table.appendChild(tr);
      }

      div.appendChild(h2);
      div.appendChild(table);
      return div;
    }; //表格数据转换


    const setTableData = val => {
      let data = [];

      for (let i = 0; i < val.length; i++) {
        data.push({
          materialsName: val[i].materialsName,
          materialsSpecs: val[i].materialsSpecs,
          supplierName: formState.supplierName,
          materialsNum: val[i].materialsNum || val[i].warehouseNum
        });
      }

      return data;
    }; //格式转换


    const formatJson = (filterVal, jsonData) => {
      return jsonData.map(v => filterVal.map(j => v[j]));
    }; //设置步骤条样式


    const setStatus = val => {
      const isError = val.some(item => item.isReject === 1);

      if (isError) {
        return 'error';
      }
    }; //入库信息--存放位置
    //添加存放位置


    const addMaterialsLocation = (index, id) => {
      formStateForRuk.warehouseMaterials[index].materialsLocationList.push({
        materialsId: id,
        methodType: 1,
        materialsNum: null,
        physicsLocation: null
      });
    }; //删除存放位置


    const deleteMaterialsLocation = (index, idx) => {
      formStateForRuk.warehouseMaterials[index].materialsLocationList.splice(idx, 1);
    };

    const weiArr = ref([]);

    const handleSearch = async val => {
      const param = {
        physicsLocationLike: val
      };
      let res = await selectLocationByParam(param);

      try {
        weiArr.value = [];

        if (res.data.length) {
          res.data.forEach(ixt => {
            weiArr.value.push({
              value: ixt.physicsLocation,
              text: ixt.physicsLocation
            });
          }); //如果当前返回的数据中没有当前的值，则把当前值放进去

          let bool = res.data.some(ixt => ixt.physicsLocation === val);

          if (!bool && val) {
            weiArr.value.unshift({
              value: val,
              text: val
            });
          }

          uniqueJsonArray(weiArr.value, 'text');
        } else {
          if (val) {
            weiArr.value.push({
              value: val,
              text: val
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    const uniqueJsonArray = (array, key) => {
      for (var i = 0; i < array.length; i++) {
        for (var j = array.length - 1; j > 0; j--) {
          if (array[i][key] == array[j][key] && i != j) {
            array.splice(j, 1);
          }
        }
      }

      return array;
    }; //  const handleSearch = async(val,index) => {
    //   if(!val){
    //     weiArr.value = []
    //     return
    //   }
    //   let  res = await selectBillByParam(val)
    //   // console.log(res)
    //   try {
    //     formState.materials[index].materialsNameData = []
    //     if(res.data.length){
    //       res.data.forEach( ixt => {
    //        formState.materials[index].materialsNameData.push({
    //           value: ixt.materialsName,
    //           text: ixt.materialsName,
    //         })
    //       })
    //       //如果当前返回的数据中没有当前的值，则把当前值放进去
    //       let bool = res.data.some(ixt => ixt.materialsName === val)
    //       if(!bool && val !==" ") {
    //        formState.materials[index].materialsNameData.unshift({
    //           value: val,
    //           text: val,
    //         })
    //       }
    //     }else {
    //       formState.materials[index].materialsNameData.push({
    //         value: val,
    //         text: val,
    //       })
    //     }
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }
    //所属项目查询


    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 1,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    }; //所属专业


    const professionalOptions = ref([]);

    const getProfessionalByItem = async () => {
      let res = await selectProfessionalByItem({
        itemId: formState.itemId
      });

      if (res.code === 200) {
        professionalOptions.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            professionalOptions.value.push({
              value: item.professionalName,
              label: item.professionalName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    onMounted(() => {
      getApprovalItem();
    });
    return {
      closeApplyVisible,
      formRef,
      formState,
      rules,
      addMaterialInformation,
      deleteMaterialInformation,
      handleOk,
      loading,
      getAdjuncts,
      isDisabledForCl,
      isDisabledForSp,
      setRoleType,
      getCurrent,
      disabledDate,
      handlePassOrReject,
      detUrl,
      formStateForRuk,
      formRefForRuk,
      isDisabledForRuk,
      numValidator,
      isInspect,
      getAdjunctsForRuk,
      handlePrint,
      handleDownExcel,
      setStatus,
      addMaterialsLocation,
      deleteMaterialsLocation,
      handleSearch,
      weiArr,
      weiValidator,
      projectOptions,
      professionalOptions,
      getProfessionalByItem
    };
  }

});